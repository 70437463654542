  <template>
  <van-radio :name="name" :key="key">
    <template #icon="props">
      <img class="img-icon" :src="props.checked ? ActiveCircleImg : InActiveCircleImg"/>
    </template>
    <slot></slot>
  </van-radio>
</template>

<script>
import { computed , ref} from 'vue'
import ActiveCircleImg from '../assets/activeCircle.png'
import InActiveCircleImg from '../assets/inactiveCircle.png'
export default {
  name: 'CheckBox',
  props: {
    name: '',
    key: ''
  },
  setup(props, {attrs, slots , emit}){
    return {
      ActiveCircleImg,
      InActiveCircleImg,
    }
  },

}
</script>

<style scoped lang="scss">
.img-icon{
  width: 16Px;
  margin-top: -6px;
}

</style>
