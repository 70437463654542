<template>
	<div class="page ">
		<div class="select-style">
			<div class="flex">
				<p class="select-style__title fs-14">采集方式：</p>
				<small class="color-warning fs-12">（系统暂不支持采集群内企业微信用户的消息）</small>
			</div>

			<van-radio-group v-model="selectType" class="ml-4" @change="changeSelect">
				<radio name="1" class="mt-4">
					<span class="select-radio">采集群内所有消息</span>
				</radio>
				<radio name="0" class="mt-5 " style="align-items: normal">
					<p class="select-radio">采集群内指定消息</p>
					<p class="mt-1  color-gray fs-12">最多可指定采集10个成员的群消息</p>
				</radio>
			</van-radio-group>
		</div>
		<div class="select-box" v-show="selectType === '0'">
			<div class="relative bg-white content s1 flex mt-4">
				<van-search v-model="likeName"
				            placeholder="搜索"
				            shape="round"
				            class="flex-1"
				            @update:model-value="debounceData"/>
				<van-button type="primary"
				            round
				            size="small"
				            class="mr-3"
				            style="padding: 4px 13px;height: 26px"
				            @click="restData">刷新
				</van-button>
				<van-button type="primary"
				            plain
				            round
				            size="small"
				            class="mr-3"
				            style="padding: 4px 13px"
				            @click="syncGroup()">同步
				</van-button>
			</div>
			<div class="divider s1"></div>
			<van-list v-model:loading="loading"
			          :finished="finished"
			          finished-text=""
			          @load="getData">
				<div class="s1 p-card">
					<no-data v-if="list.length===0 && !loading"
					         title="暂无群成员数据，请同步"
					         :show-button="false"/>
					<div v-for="(item,index) in list"
					     :key="item.robotWxId"
					     class="item flex"
					     @click="changeTeacher(index)">
						<input v-model="item.isTeacher"
						       type="checkbox"
						       class="radio mr-1">
						<div class="avatar"><img :src="item.headImgUrl"></div>
						<div class="flex-1">
							<div class="p1">{{ item.groupNickName ? item.groupNickName : item.nickName }}
							</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>
		<div class="p-footer h-end">
			<button class="btn auto "
			        @click="$router.go(-1)">取消
			</button>
			<button class="btn fill ml-3 "
			        @click="handleSave">确定
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import Radio from '@/components/Radio'
import CheckBox from '@/components/CheckBox'
import { debounce, throttle } from '@/utils'
import { ApiQueryChatroomMembers, ApiSetTeacher } from '@/apis/FollowLive'
import { ApiSyncChatroomMember } from '@/apis/Common'

export default {
	name: 'Index',
	components: { CheckBox, Radio },

	data() {
		return {
			list: [],
			chatroomList: [],
			chatroomId: '',
			pageIndex: 1,
			pageSize: 15,
			loading: false,
			finished: false,
			likeName: '',

			// 采集方式 0 指定 1 所有
			selectType: '1',
			alreadyInit: false,
			debounceData: null,
			throttleAsyncGroup: null
		}
	},
	computed: {
		...mapGetters([ 'robotInfo']),
		teacherWxIds() {
			return this.list.filter(i => i.isTeacher)
			           .map(i => i.memberWxId)
		}
	},
	created() {
		const _this = this
		_this.chatroomId = _this.$route.query.chatroomId
		this.debounceData = debounce(this.getData, 1000)
		this.throttleAsyncGroup = throttle(this.syncGroup, 5000)
	},
	methods: {
		// 选择采集方式
		changeSelect(val) {
			if (!this.alreadyInit && val === '0') {
				this.getData()
			} else {
				this.clearChecked()
			}
		},
		restData() {
			this.likeName = ''
			this.getData()
		},
		clearChecked() {
			this.list.map(item => {
				item.isChecked = false
			})
		},
		async getData() {
			this.loading = true
			try {
				const data = {
					chatroomId: this.chatroomId,
					robotWxId: this.robotInfo.robotWxId,
					likeName: this.likeName
				}
				this.list = await ApiQueryChatroomMembers({ ...data })
				this.loading = false
				this.finished = true
			} catch (e) {
				this.loading = false
				this.finished = true
			}
		},
		async syncGroup() {
			await ApiSyncChatroomMember({
				robotWxId: this.robotInfo.robotWxId,
				chatroomId: this.chatroomId
			})
			this.$Toast('同步成功,请点击刷新按钮进行刷新！')
			await this.getData()
		},

		changeTeacher(index) {
			const _this = this
			const flag = _this.list[index].isTeacher
			if (this.teacherWxIds.length > 9 && !flag) {
				this.$Toast({
					message: '最多可指定采集10个成员的群消息！',
					duration: 1000
				})
				return
			}
			_this.list[index].isTeacher = !_this.list[index].isTeacher
		},

		async handleSave() {
			if (this.selectType === '0') {
				if (this.teacherWxIds.length === 0) {
					this.$Toast('请先选择某一项！')
					return
				}
			}
			const toast1 = Toast.loading({
				message: '设置中...',
				forbidClick: true,
				loadingType: 'spinner'
			})
			let param = {
				robotWxId: this.robotInfo.robotWxId,
				chatroomId: this.chatroomId,
				teacherWxIds: this.teacherWxIds,
				routeType: this.selectType
			}
			await ApiSetTeacher({ ...param })
			toast1.clear()
			await this.$router.replace('/gather')
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";

.no-event {
	pointer-events: none;
}
</style>
